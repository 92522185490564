import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCode, faCoffee, faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {Col, Container, NavLink, Row} from "react-bootstrap";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";

const ContactDetails = () => {
    return (
        <>
            <hr className="text-primary"/>
            <Container>
                <Row className="text-light">
                    <Col className="d-flex flex-row justify-content-start align-items-center">
                        <FontAwesomeIcon className="me-2 text-primary" icon={faPhone}/>
                        <p className="mb-0">07850349200</p>
                    </Col>
                    <Col className="d-flex flex-row justify-content-start align-items-center">
                        <FontAwesomeIcon className="me-2 text-primary" icon={faEnvelope}/>
                        {/*TODO make "Email Us" gold on hover*/}
                        <NavLink className="p-0 text-light" href="mailto: bdmbuildingprojects@gmail.com">Email Us</NavLink>
                    </Col>
                </Row>
            </Container>
            <hr className="text-primary"/>
            <Container>
                <a href="https://www.instagram.com/bdmbuildingprojects/" target="_blank">
                    <FontAwesomeIcon className="text-primary" icon={faInstagram}/>
                </a>
            </Container>


        </>
    );
}

export default ContactDetails;